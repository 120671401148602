<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h4>TFF data for: {{ instruments[0].name }}</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4">
        <label>Change data set</label>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4">
        <select v-model="dataSet">
          <option
            v-for="(exchange, index) in exchanges"
            v-bind:key="index"
            v-bind:value="exchange.name">
            {{ exchange.name }}
          </option>
        </select>
      </div>
      <div class="cell small-12 medium-4">
        <button v-on:click="changeData" class="button">Change Data Set</button>
      </div>
    </div>
    <hr />
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <label>Select data type</label>
        <select v-model="dataType">
          <option value="1">Futures</option>
          <option value="2">Options</option>
          <option value="3">Combined</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Include Spread?</label>
        <div class="switch large">
          <input
            v-model="includeSpread"
            class="switch-input"
            id="inc-spr"
            type="checkbox"
            name="inc-spr-switch">
          <label class="switch-paddle" for="inc-spr">
            <span class="show-for-sr">Include Spread?</span>
            <span class="switch-active" aria-hidden="true">Y</span>
            <span class="switch-inactive" aria-hidden="true">N</span>
          </label>
        </div>
      </div>
      <div class="cell small-12 medium-2">
        <label>Position?</label>
        <select v-model="position">
          <option value="1">Dealer</option>
          <option value="2">Asset</option>
          <option value="3">Leveraged</option>
          <option value="4">Other</option>
          <option value="5">Small</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Date Range Start</label>
        <datepicker id="crs" v-model="dateRangeStart"></datepicker>
      </div>
      <div class="cell small-12 medium-2">
        <label>Date Range End</label>
        <datepicker id="cre" v-model="dateRangeEnd"></datepicker>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table class="wide-table">
          <thead>
            <tr>
                <th colspan="2"></th>
                <th colspan="5">LONG OI</th>
                <th colspan="5">SHORT OI</th>
            </tr>
            <tr>
              <th>Date</th>
              <th>Open Interest</th>
              <th class="blue">Dealer</th>
              <th class="brown">Asset</th>
              <th class="grey">Leveraged</th>
              <th class="yellow">Other</th>
              <th class="lightblue">Small</th>
              <th class="blue">Dealer</th>
              <th class="brown">Asset</th>
              <th class="grey">Leveraged</th>
              <th class="yellow">Other</th>
              <th class="lightblue">Small</th>
              <th class="blue">Long</th>
              <th class="blue">Short</th>
              <th class="blue">Long CH</th>
              <th class="blue">Short CH</th>
              <th class="blue">Total</th>
              <th class="blue">Index</th>
              <th class="blue">Move</th>
              <th class="brown">Long</th>
              <th class="brown">Short</th>
              <th class="brown">Long CH</th>
              <th class="brown">Short CH</th>
              <th class="brown">Total</th>
              <th class="brown">Index</th>
              <th class="brown">Move</th>
              <th class="grey">Long</th>
              <th class="grey">Short</th>
              <th class="grey">Long CH</th>
              <th class="grey">Short CH</th>
              <th class="grey">Total</th>
              <th class="grey">Index</th>
              <th class="grey">Move</th>
              <th class="yellow">Long</th>
              <th class="yellow">Short</th>
              <th class="yellow">Long CH</th>
              <th class="yellow">Short CH</th>
              <th class="yellow">Total</th>
              <th class="yellow">Index</th>
              <th class="yellow">Move</th>
              <th class="green">Long</th>
              <th class="green">Short</th>
              <th class="green">Long CH</th>
              <th class="green">Short CH</th>
              <th class="green">Total</th>
              <th class="green">Index</th>
              <th class="green">Move</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(instrument, index) in filteredInstruments" v-bind:key="index">
              <td>{{ instrument.date | moment("Do MMM YYYY") }}</td>
              <td>{{ instrument.e | numeral('0,0.[00]') }}</td>
              <td class="blue">{{ parseFloat(instrument.an) + parseFloat(instrument.ap)
                  | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ parseFloat(instrument.aq) + parseFloat(instrument.aq)
                  | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ parseFloat(instrument.at) + parseFloat(instrument.av)
                  | numeral('0,0.[00]') }}</td>
              <td class="yellow">{{ parseFloat(instrument.aw) + parseFloat(instrument.ay)
                  | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.bb | numeral('0,0.[00]') }}</td>
              <td class="blue">{{ parseFloat(instrument.ao) + parseFloat(instrument.ap)
                  | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ parseFloat(instrument.ar) + parseFloat(instrument.ass)
                  | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ parseFloat(instrument.au) + parseFloat(instrument.av)
                  | numeral('0,0.[00]') }}</td>
              <td class="yellow">{{ parseFloat(instrument.ax) + parseFloat(instrument.ay)
                  | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.bc | numeral('0,0.[00]') }}</td>
              <td class="blue">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.f) + parseFloat(instrument.h) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.f  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="blue">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.g) + parseFloat(instrument.h) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.g  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="blue">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.w) + parseFloat(instrument.y) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.w  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="blue">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.x) + parseFloat(instrument.y) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.x  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="blue">
                  {{ (parseFloat(instrument.f) - parseFloat(instrument.g))  | numeral('0,0.[00]') }}
              </td>
              <td class="blue"></td>
              <td class="blue"></td>
              <td class="brown">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.i) + parseFloat(instrument.k) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.i  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="brown">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.j) + parseFloat(instrument.k) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.j  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="brown">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.z) + parseFloat(instrument.ab) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.z  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="brown">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.aa) + parseFloat(instrument.ab)
                    | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.aa  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="brown">
                  {{ (parseFloat(instrument.i) - parseFloat(instrument.j)) | numeral('0,0.[00]') }}
              </td>
              <td class="brown"></td>
              <td class="brown"></td>
              <td class="grey">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.l) + parseFloat(instrument.n) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.l  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="grey">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.m) + parseFloat(instrument.n) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.m  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="grey">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.ac) + parseFloat(instrument.ae)
                    | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.ac  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="grey">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.ad) + parseFloat(instrument.ae)
                    | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.ad  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="grey">
                  {{ (parseFloat(instrument.l) - parseFloat(instrument.m)) | numeral('0,0.[00]') }}
              </td>
              <td class="grey"></td>
              <td class="grey"></td>
              <td class="yellow">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.o) + parseFloat(instrument.q) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.o | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="yellow">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.p) + parseFloat(instrument.q) | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.q | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="yellow">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.af) + parseFloat(instrument.ah)
                    | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.af  | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="yellow">
                  <span v-if="includeSpread === true">
                    {{ parseFloat(instrument.ag) + parseFloat(instrument.ah)
                    | numeral('0,0.[00]') }}
                  </span>
                  <span v-else>
                      {{ instrument.ag | numeral('0,0.[00]') }}
                  </span>
              </td>
              <td class="yellow">
                  {{ (parseFloat(instrument.o) - parseFloat(instrument.p))
                  | numeral('0,0.[00]') }}
              </td>
              <td class="yellow"></td>
              <td class="yellow"></td>
              <td class="green">{{ instrument.t | numeral('0,0.[00]') }}</td>
              <td class="green">{{ instrument.u | numeral('0,0.[00]') }}</td>
              <td class="green">{{ instrument.ak | numeral('0,0.[00]') }}</td>
              <td class="green">{{ instrument.al | numeral('0,0.[00]') }}</td>
              <td class="green">
                  {{ (parseFloat(instrument.t) - parseFloat(instrument.u)) | numeral('0,0.[00]') }}
              </td>
              <td class="green"></td>
              <td class="green"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from '../axios';

export default {
  name: 'ViewTff',
  data() {
    return {
      exchanges: [],
      dataType: 1,
      dataSet: 'EURO FX - CHICAGO MERCANTILE EXCHANGE',
      includeSpread: false,
      instruments: [],
      filteredInstruments: [],
      position: 1,
      dateRangeStart: new Date(2021, 0, 1),
      dateRangeEnd: new Date(),
    };
  },
  components: {
    Datepicker,
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  watch: {
    dateRangeStart(value) {
      const newDate = new Date(value);
      this.filteredInstruments = this.instruments;
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > newDate));
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
    },
    dateRangeEnd(value) {
      const newDate = new Date(value);
      this.filteredInstruments = this.instruments;
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < newDate));
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
    },
    dataType(value) {
      this.instruments = [];
      axios.get(`/tffs/getInstruments/${value}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
        });
    },
  },
  methods: {
    getExchanges() {
      axios.get(`/tffs/getExchanges.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.exchanges = response.data.exchanges;
        });
    },
    getInstruments() {
      axios.get(`/tffs/getInstruments/1.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
        });
    },
    changeData() {
      this.instruments = [];
      axios.get(`/tffs/getInstruments/${this.dataType}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&exchange=${this.dataSet}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
        });
    },
  },
  mounted() {
    this.getExchanges();
    this.getInstruments();
  },
};
</script>
